<template>
  <div class="sidebar"
       :data="backgroundColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <sidebar-database v-if="selectedDatabase != null"></sidebar-database>
    <sidebar-dashboard v-else></sidebar-dashboard>
  </div>
</template>
<script>
  import SidebarDashboard from "./SidebarDashboard";
  import SidebarDatabase from "./SidebarDatabase";

  export default {
    props: {
      backgroundColor: {
        type: String,
        // default: "raima-red"
        default: "white"
        // default: "white"
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose,
      };
    },
    components: {
      SidebarDashboard,
      SidebarDatabase
    },
    computed: {
      selectedDatabase() {
        return this.$route.params.database;
      }
    },
    methods: {
      
    },
  };
</script>
