<template>
  <div class="sidebar-dashboard">
    <div>
        <ul id="navigation-items">
          <li>
            <a href="https://raima.com/" target="_blank">
                <div class="icon-wrapper">
                  <i class="tim-icons icon-link-72"></i>
                </div>
                Home Page
              </a>
            </li>
          <li>
            <a href="https://docs.raima.com/rdm/14_2/Homepage.htm" target="_blank">
              <div class="icon-wrapper">
                <i class="tim-icons icon-link-72"></i>
              </div>
              Documentation
              </a>
            </li>
          <li>
            <a href="https://raima.com/support/" target="_blank">
              <div class="icon-wrapper">
                <i class="tim-icons icon-link-72"></i>
              </div>
              Support
              </a>
            </li>
        </ul>
    </div>

    <div class="sidebar-footer">
      <div class="divider"></div>
    
      <div class="version-info-container" v-if="(typeof versionInfo !== 'undefined') && versionInfo.length !==0">
        <p>{{versionInfo.name}} {{versionInfo.version}}｜Build {{versionInfo.build}} </p>
        <p v-html="formatVersionInfo(versionInfo.copyright)"></p>
      </div>
    </div>

    
  </div>
</template>
<script>

  export default {
    props: {
      title: {
        type: String,
        default: "Back"
      },
      backgroundColor: {
        type: String,
        default: "vue"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          let acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose,
        addLink: this.addLink,
        removeLink: this.removeLink
      };
    },
    components: {
      
    },
    computed: {
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx() {
        return this.linkHeight * this.activeLinkIndex;
      },
      shortTitle() {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
      },
      versionInfo() {
        return this.$store.getters["auth/version_info"]
      }
    },
    data() {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: []
      };
    },
    methods: {
      findActiveLink() {
        this.links.forEach((link, index) => {
          if (link.isActive()) {
            this.activeLinkIndex = index;
          }
        });
      },
      addLink(link) {
        const index = this.$slots.links.indexOf(link.$vnode);
        this.links.splice(index, 0, link);
      },
      removeLink(link) {
        const index = this.links.indexOf(link);
        if (index > -1) {
          this.links.splice(index, 1);
        }
      },
      goBack() {
        router.go(-1);
      },
      formatVersionInfo(text){
        if(!text) return
        return text.replace(/(\\r)*\\n/g, '<br>')
      }
    },
    mounted() {
      this.$watch("$route", this.findActiveLink, {
        immediate: true
      });
    }
  };
</script>
